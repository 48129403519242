import React, { useEffect, useRef } from "react";

export default function LocalScript({
  isSrc = false,
  src,
  html,
  isHeadScript = false,
  firstRemoveScript = "",
}) {
  const scriptRef = useRef(null);
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://cdn.convertbox.com/convertbox/js/embed.js";
    script.id = "app-convertbox-script";
    script.async = true;
    script.dataset.uuid = "a1ce5f9e-d630-4668-be5b-3554f61e94a7";
    document.getElementsByTagName("head")[0].appendChild(script);
  };
  useEffect(() => {
    loadScript();
    // Cleanup function if necessary
    // return () => {
    //   console.log("====cleanup====");
    //   // Remove the script if needed
    //   const scriptToRemove = document.querySelector(
    //     `script[src='https://cdn.convertbox.com//convertbox/js/embed-core.js?id=d52f4c09a24bf2889838']`
    //   );
    //   const linkToRemove = document.querySelector(
    //     `link[href='https://cdn.convertbox.com//static/css/bars-preview.css?id=bcd08c616f2a1e4f6182']`
    //   );
    //   console.log("===find script", scriptToRemove);
    //   console.log("===find llink", linkToRemove);
    //   if (scriptToRemove) {
    //     scriptToRemove.remove();
    //   }
    //   if (linkToRemove) {
    //     linkToRemove.remove();
    //   }
    //   const script = document.getElementById("app-convertbox-script");
    //   if (script) {
    //     console.log("======script found", script);

    //     script.parentNode.removeChild(script);
    //   }
    // };
  }, []);

  return <div ref={scriptRef}></div>;
}
